$(document).on('turbolinks:load', function () {
  /**
   * DOM操作系のJSはこのファイルに記述
   */

  //ナビ横スライド
  $('.js-nav').on('click', function() {
    $('.custom-nav').toggleClass('is-active');
  });

  //  行数カット
  var $trunk8 = $('.trunk8');
  var lines = 1;
  if ($trunk8.length > 0) {
    $trunk8.each(function () {
      if ($(this).attr("data-line")) {
        lines = $(this).attr("data-line");
      } else {
        lines = 1;
      }
      $(this).trunk8({
        lines: lines
      });
    });
  }

  //プロフィールアイコンアップ
  $('.js-up-file').on('change', function(e) {
    var self = $(this);
    var file = $(this).prop('files')[0];
    if (!file.type.match('image.*')) {
      return;
    }
    var reader = new FileReader();
    reader.onload = (function (file, self) {
      return function (e) {
        self.parent().find('.js-preview').attr('src', e.target.result);
        self.parent().find('.js-preview').attr('title', file.name);
      };
    })(file, self);
    reader.readAsDataURL(file);
  });

  // 通知メニュー表示
  $(document).on('click touchend', function (e) {
    if (!($(e.target).closest('#notification_trigger').length || $(e.target).closest('#notification_list').length)) {
      $('#notification_list').remove();
    }
  });

  //やり取りtextareaの高さ自動
  var $textarea = $('.auto-resize');
  $textarea.on('input', function () {
    var lines = ($(this).val() + '\n').match(/\n/g).length;
    if (lines < 2) {
      lines = 2;
    }
    var calcHeight = 20 * lines;
    $(this).height(calcHeight);
  });

  //依頼作成時のその他選択
  var $requestOtherInput = $('#request_purpose_other_input');
  $requestOtherInput.hide();
  $('#request_purpose_id').on('change', function () {
    var selectVal = $(this).find("option:selected").text();
    if (selectVal === 'その他') {
      $requestOtherInput.show();
    } else {
      $requestOtherInput.hide();
    }
  });

  // セレクトボックスの切り替えで画面遷移
  $(document).on('change', '[data-link="select"]', function() {
    var $selectedOption = $(this).find("option:selected");
    if ($selectedOption.data('path') !== '') {
      window.location.href = $selectedOption.data('path');
    }
  });

  //新規依頼作成ボタンフローティング
  var $jsFloatingBar = $('.js-floating-bar');
  if ($jsFloatingBar.length > 0) {
    // var jsFloatingBarH = $jsFloatingBar.outerHeight(true);
    var jsFloatingBarH = 80;
    $jsFloatingBar.css({ 'bottom': -jsFloatingBarH });
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $jsFloatingBar.css({ 'bottom': 0 });
      } else {
        $jsFloatingBar.css({ 'bottom': -jsFloatingBarH });
      }
    });
    $('body').css({ 'padding-bottom': jsFloatingBarH});
  }

  window.$('.rate').rateYo({
    onSet: function (rating, rateYoInstance) {
      $(this).next().val(rating);
    },
    rating: 3,
    starWidth: "20px",
    numStars: 5,
    fullStar: true
  });

  $('.js-list-num').each(function () {
    $(this).find('li').each(function (i) {
      console.log(i);
      let  num = i + 1;
      $(this).prepend('<span class="list-num">(' + num + ')</span>')
    });
  });

  flatpickr(".date-picker", {
    locale:"ja",
    minDate:"today",
    altInput: true,
    altFormat: "Y年m月d日",
    dateFormat: "Y-m-d"
  });

  // 提案作成画面の料金計算
  // 商品価格フォームの入力でサービス手数料と受託者報酬額を算出
  var $price = $(".price");
  $price.on('change', function() {
    var $serviceFee = $('.service_fee');
    var $trusteeFee = $('.trustee_fee');
    var price = parseInt($(this).val());
    var serviceFee = price * 0.1;
    if (isNaN(serviceFee) || serviceFee < 500) {
      serviceFee = 500
    }
    var trusteeFee = Math.round(serviceFee / 1.1) * 0.5;
    $serviceFee.text(Math.floor(serviceFee)).toString();
    $trusteeFee.text(Math.floor(trusteeFee)).toString();
  });

  // 商品価格フォームと送料の入力で合計金額を算出
  $(".price, .postage").on('change', function() {
    var price = parseInt($('.price').val());
    if (isNaN(price)) {
      price = 0
    }
    var postage = parseInt($('.postage').val());
    if (isNaN(postage)) {
      postage = 0
    }
    var serviceFee = price * 0.1;
    if (isNaN(serviceFee) || serviceFee < 500) {
      serviceFee = 500
    }

    let totalAmount = price + serviceFee + postage;
    $('.total_amount').text(Math.floor(totalAmount)).toString();
  });
  $price.trigger('change');
  $(".postage").trigger('change');

  //依頼作成の配送先選択と住所フォーム切り替え
  var $deliveryTarget = $('input[name="request[form_delivery_target]"]');
  $($deliveryTarget).on('change', function () {
    var $requesterAddressForm = $('#requester-address-form');
    var $otherAddressForm = $('#other-address-form');
    var targetVal = $('input[name="request[form_delivery_target]"]:checked').val();
    if (targetVal === 'requester') {
      $requesterAddressForm.show();
      $otherAddressForm.hide();
    } else {
      $otherAddressForm.show();
      $requesterAddressForm.hide();
    }
  });
  $deliveryTarget.trigger('change');

  var $scenesContainer = $('.scenes-container');
  if ($scenesContainer.length > 0) {
    $scenesContainer.slick({
      infinite: true,
      dots: true,
      arrows: true,
      appendDots: $(".scenes-pagination"),
      prevArrow: $(".scenes-navigation-prev"),
      nextArrow: $(".scenes-navigation-next")
    });
  }

  var $presenterContainer = $('.presenter-container');
  if ($presenterContainer.length > 0) {
    $presenterContainer.slick({
      infinite: true,
      arrows: false,
      slidesToShow: 4,
      centerMode: true,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 1500,
    });
  }

  // 画面表示時にモーダルを表示
  var $showModalWhenLoadPage = $('.modal[data-load-page="true"]');
  if ($showModalWhenLoadPage.length > 0) {
    $showModalWhenLoadPage.modal('show');
  }

  if ($('#is_send_push').prop("checked")) {
    $('#heading-title-content').css({'cssText': 'display: flex !important;'});
  } else {
    $('#heading-title-content').css({'cssText': 'display: none !important;'});
  }

  $('#is_send_push').click(function() {
    if (this.checked) {
      $('#heading-title-content').css({'cssText': 'display: flex !important;'});
    } else {
      $('#heading-title-content').css({'cssText': 'display: none !important;'});
      $('#admin_notification_heading_title').val('');
    }
  });
});

$(window).on('turbolinks:before-cache', function () {
  var $scenesContainer = $('.scenes-container');
  if ($scenesContainer.length > 0) {
    $scenesContainer.slick('unslick');
  }

  var $presenterContainer = $('.presenter-container');
  if ($presenterContainer.length > 0) {
    $presenterContainer.slick('unslick');
  }
});
