import 'jquery/src/jquery'
import 'bootstrap'
import 'bootstrap4-datetimepicker/src/js/bootstrap-datetimepicker'
import 'moment/moment'
import 'moment/locale/ja'
import 'rateyo/src/jquery.rateyo'
import 'flatpickr/dist/flatpickr'
import 'flatpickr/dist/l10n/ja.js'
import 'slick-carousel/slick/slick'

import Rails from 'rails-ujs'
import * as ActiveStorage from 'activestorage'
import Turbolinks from 'turbolinks';
import {config, library, dom} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

Rails.start();
ActiveStorage.start();
Turbolinks.start();

config.mutateApproach = 'sync';
library.add(fas, far, fab);
dom.watch();

import './trunk8';
import './dom';
